<template>
  <div>
    <!-- First the shapes -->
    <div
      class="sb_backdrop_layer-1"
    >
      <div
        class="sb_backdrop_layer-1_shape-left"
      >
      </div>

      <div
        class="sb_backdrop_layer-1_shape-right"
      >
      </div>
    </div>


    <!-- Logo's on top of the shapes -->
    <div class="sb_backdrop_layer-2">
      <div class="sb_backdrop_layer-2_left">
        <img
          style="opacity: 0.05"
          class="sb_backdrop_layer-2_left_image"
          :class="{sb_animate_logo_left: shapesAnimated}"
          src="images/backdrop-eli5.png"
          alt=""
        >
      </div>

      <div class="sb_backdrop_layer-2_right">
        <img
          style="opacity: 0.05"
          class="sb_backdrop_layer-2_right_image"
          :class="{sb_animate_logo_right: shapesAnimated}"
          src="images/backdrop-stijlbreuk-logo.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  props: [
    'shapesAnimated',
    'teamOneLeading',
    'scoreOne',
    'scoreTwo'
  ],
  computed: {
    backgroundColorRight() {
      if (this.teamOneLeading === true) {
        return '#3D3D3D';
      }
      return '#F2F2F2';
    },
    backgroundColorLeft() {
      if (this.teamOneLeading === true) {
        return '#2F2F2F';
      }
      return '#EBEBEB';
    },
    backgroundSkew() {
      if (this.teamOneLeading === true) {
        return '15deg';
      }
      return '-15deg';
    },
    backgroundImageOpacity() {
      if (this.teamOneLeading === true) {
        return '0.5';
      }
      return '0.05';
    }
  },
  watch: {
    teamOneLeading(newVal) {
      this.animateShapes();
      this.animateImages();
    }
  },
  mounted() {
    console.log('t1l', this.teamOneLeading);
    this.animateShapes();
    this.animateImages();
  },
  created() {
  },
  methods: {
    animateShapes() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      anime({
        targets: '.sb_backdrop_layer-1_shape-left',
        skewX: 0,
        keyframes: [
          {
            translateX: [
              -vw / 2 + -300,
              0
            ],
            delay: 2000,
            duration: 400,
            easing: 'easeOutElastic(1, .9)'
          },
          {
            delay: 1300,
            duration: 1,
            background: this.backgroundColorLeft,
            easing: 'linear'
          },
          {
            skewX: this.backgroundSkew,
            delay: 0,
            duration: 400,
            easing: 'easeOutExpo'
          }
        ]
      });
      anime({
        targets: '.sb_backdrop_layer-1_shape-right',
        skewX: 0,
        keyframes: [
          {
            translateX: [
              vw / 2 + 300,
              0
            ],
            delay: 2300,
            duration: 400,
            easing: 'easeOutElastic(1, .9)'
          },
          {
            delay: 1000,
            duration: 1,
            background: this.backgroundColorRight,
            easing: 'linear'
          },
          {
            skewX: this.backgroundSkew,
            delay: 0,
            duration: 400,
            easing: 'easeOutExpo'
          }
        ]
      });
    },
    animateImages() {
      anime({
        targets: '.sb_backdrop_layer-2_left_image',
        keyframes: [
          {
            translateX: [
              -1000,
              0
            ],
            delay: 2000,
            duration: 400,
            easing: 'easeOutElastic(1, .9)'
          },
          {
            delay: 1300,
            opacity: this.backgroundImageOpacity,
            duration: 1,
            easing: 'linear'
          }
        ]
      });
      anime({
        targets: '.sb_backdrop_layer-2_right_image',
        keyframes: [
          {
            translateX: [
              1000,
              0
            ],
            delay: 2300,
            duration: 400,
            easing: 'easeOutElastic(1, .9)'
          },
          {
            delay: 1000,
            duration: 100,
            opacity: this.backgroundImageOpacity
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sb_backdrop_layer-1{
  &_shape-left{
  background-color: #EBEBEB;
  display: block;
    height: 100%;
    width: 61%;
    top: 0;
    left: -10%;
    position: absolute;
    transform: skewX(-15.0deg) translateX(-1400px);
    transform-origin: 100%;
    z-index: -2;
  }

  &_shape-right{
  content: '';
  background-color: #F2F2F2;
  display: block;
    height: 100%;
    width: 61%;
    right: -10%;
    top: 0;
    position: absolute;
    transform: skewX(-15.0deg) ;
    transform-origin: 100%;
    z-index: -1;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  }
}

.sb_backdrop_layer-2{
  display: flex;
  width: 100vw;
  height: 100vh;

  &_left{
    flex: 1;
    display: flex;
    align-items: flex-end;
  }

  &_right{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
  }
}


</style>
