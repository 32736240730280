<template>
  <div>
    <main>
      <RouterView />
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([
      'userProfile'
    ]),
    showNav() {
      return Object.keys(this.userProfile).length > 1;
    }
  }
};
</script>
