<template>
  <div class="sb_login">
    <div
      class="sb_login_header"
      :class="{disabled: selectedAccount}"
    >
      <div
        class="sb_login_header_title"
      >
        Login
      </div>
      <div class="sb_login_description">
        Selecteer een account
      </div>
    </div>
    <div
      class="sb_login_userselector"
    >
      <transition name="fade">
        <div
          class="sb_login_userselector_profile"
          :class="{disabled: selectedAccount === 'Stijlbreuk'}"
          @click="accountSelected('ELI5')"
        >
          <img
            src="images/profile-ELI5.png"
            alt=""
          >
          <p>ElI5</p>
        </div>
      </transition>
      <div
        class="sb_login_userselector_profile"
        :class="{disabled: selectedAccount === 'ELI5'}"
        @click="accountSelected('Stijlbreuk')"
      >
        <img
          src="images/profile-stijlbreuk.png"
          alt=""
        >
        <p>Stijlbreuk</p>
      </div>
    </div>

    <div
      :class="{disabled: selectedAccount === null}"
      class="sb_login_password_field"
      action=""
    >
      <input
        v-model="password"
        placeholder="wachtwoord"
        type="password"
        @keyup.enter="login"
      >
      <button
        class="sb_login_password_field_submit"
        @click="login"
      >
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.48038 10.3679L10.3028 5.54545L5.48038 0.723011L4.45623 1.74219L7.5237 4.80469H0.0712891V6.28622H7.5237L4.45623 9.35369L5.48038 10.3679Z"
            fill="white"
          />
        </svg>
      </button>
    </div>

    <div
      :class="{disabled: selectedAccount === null}"
      class="sb_login_wrong_profile"
      @click="accountSelected(null)"
    >
      Ik hoor niet bij {{ selectedAccount }}
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  data() {
    return {
      selectedAccount: null,
      password: '',
      email: null
    };
  },
  methods: {
    login() {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          this.$router.replace('dashboard');
        },
        (err) => {
          alert(`Oops! ${err.message}`);
        }
      );
      this.$emit(this.selectedAccount);
    },
    accountSelected(account) {
      this.selectedAccount = account;
      if (account === 'Stijlbreuk') {
        this.email = 'frankroodnat@gmail.com';
      } else if (account === 'ELI5') {
        this.email = 'frank@stijlbreuk.nl';
      }
    }
  }
};
</script>


<style scoped lang="scss">

body{
  transition: all .3s ease-out;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.sb_login{
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;

  &_header_title{
    font-size: 21px;
    font-weight: 700;
    color: #000;
  }

  &_header_description{
    margin-top: 4px;
    font-weight: 500;
    color: #979797;
  }
}

.sb_login_userselector{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

  &_profile{
    margin-right: 15px;
    margin-left: 15px;
    padding-top: 15px;
    width: 100px;
    border-radius: 5px;
    transition-duration: .3s;
  }

  &_profile:hover{
    background-color: #eeeeee;
    cursor: pointer;
  }

  &_profile img{
    width: 60px;
  }

  &_profile p{
    margin-top: 8px;
    font-weight: 600;
  }

}

  .disabled{
  display: none !important;
  }

.sb_login_password_field{
  display: flex;
  justify-content: center;
  margin-top: 5px;

  &_submit{
    background-color: #2C41FF;
    color: #fff;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
  }
  &_submit:hover{
  box-shadow: 0px 0px 25px rgba(44, 65, 255, 0.2);
  }
}

.sb_login_wrong_profile{
  margin-top: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #8f8f8f;
  cursor: pointer;
}

.sb_login_wrong_profile:hover{
  text-decoration: underline;
}

@keyframes slide-fade {
  0%{
    opacity: 1;
  }
  70%{
    opacity: .7;
  }
  100%{
    opacity: 0;
    z-index: -5;
  }
}

.v-enter-from{
  opacity: 1;
}

</style>
