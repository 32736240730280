import { createApp } from 'vue';
import firebase from 'firebase';

import router from './router/index';
import App from './App.vue';

let app = '';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

export {
  db,
  auth
};

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
  }
  if (!app) {
    app = createApp(App);
    app.use(router);
    app.mount('#app');
  }
});
