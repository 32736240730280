<template>
  <div>
    <canvas
      id="my-canvas"
      class="sb_confetti_canvas"
    >
    </canvas>
    <div
      id="Scoreboard"
      class="sb_scoreboard_container"
    >
      <div class="sb_badge">
        <img
          src="images/badge-rocket.png"
          alt=""
        >
      </div>

      <div class="sb_team-names">
        <div class="sb_team-names_team">
          <div class="sb_team-names_team_text">
            {{ teamOneName }}
          </div>
        </div>
        <div class="sb_team-names_team">
          <div class="sb_team-names_team_text">
            {{ teamTwoName }}
          </div>
        </div>
      </div>

      <div
        class="sb_score"
      >
        <div class="sb_score_team">
          <div class="sb_score_team_counter">
            <div
              id="scoreTeamOne"
              class="sb_score_team_counter_text"
            >
              {{ countNumberAnimation(scoreOne, '#scoreTeamOne') }}
            </div>
          </div>

          <div class="sb_score_team_logo">
            <img
              class="sb_score_team_logo_image"
              src="images/logo-ELI5.png"
              alt=""
            >
          </div>
        </div>

        <div class="sb_score_team">
          <div class="sb_score_team_logo">
            <img
              class="sb_score_team_logo_image"
              src="images/logo-stijlbreuk.png"
              alt=""
            >
          </div>

          <div class="sb_score_team_counter">
            <div
              id="scoreTeamTwo"
              class="sb_score_team_counter_text"
            >
              {{ countNumberAnimation(scoreTwo, '#scoreTeamTwo') }}
            </div>
          </div>
        </div>
      </div>

      <div class="sb_timer">
        <p>
          Nog  {{ daysRemaining }} dagen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js';
import anime from 'animejs';

export default {
  props: [
    'scoreOne',
    'scoreTwo',
    'teamOneName',
    'teamTwoName',
    'daysRemaining'
  ],
  data() {
    return {};
  },
  mounted() {
    this.zoomScoreBoardAnimation();
    // setTimeout(this.dropConfetti(), 3000);
  },
  methods: {
    // dropConfetti() {
    //   const confettiSettings = {
    //     target: 'my-canvas'
    //   };

    //   const confetti = new ConfettiGenerator(confettiSettings);
    //   confetti.render();
    // },
    countNumberAnimation(number, team) {
      anime({
        targets: team,
        textContent: [
          0,
          number
        ],
        round: 1,
        easing: 'easeOutExpo',
        duration: 3000
      });
    },
    zoomScoreBoardAnimation() {
      anime({
        targets: '#Scoreboard',
        opacity: 1,
        keyframes: [
          {
            scale: 1.25,
            duration: 3500,
            easing: 'easeOutQuad'
          },
          {
            scale: 1,
            duration: 500,
            easing: 'easeOutElastic'
          }
        ]
      });
    }
  }


};


</script>

<style lang="scss" scoped>
.sb_scoreboard_container{
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: scale(1);
}

//
// Team namen
//
.sb_team-names{
    display: flex;
    flex-direction: row;
    justify-content: center;

    &_team {
      width: 180px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 15px;

      &:nth-child(1) {
        background-color: #000;
        color: white;
        transform: skewX(-30deg);

        .sb_team-names_team_text {
          transform: skewX(30deg)
        }

      }
      &:nth-child(2) {
        background-color: white;
        color: #000;
        transform: skewX(30deg);

        .sb_team-names_team_text {
          transform: skewX(-30deg)
        }

      }

    }
}

//
// Timer
//
.sb_timer{
  position: relative;
  display: flex;
  background-color: #2C41FF;
  width: 250px;
  justify-content: center;
  color: white;
  height: 35px;
  align-items: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #2C41FF;
    width: 35px;
    height: 35px;
  }

  &:before {
    left: 0;
    transform: skewX(30deg) translateX(-50%);
  }
  &:after {
    right: 0;
    transform: skewX(-30deg) translateX(50%);
  }
}

//
// Badge
//
.sb_badge{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: -30px;
  z-index: 3;

  width: 100px;
  height: 100px;

  background-color: #fff;
  border-radius: 100%;
}

//
// team logo's en score
//
.sb_score{
  display: flex;

  &_team{
    display: flex;
    width: 350px;
    height: 90px;

    &:nth-child(1){
      background-color: #202020;

    }


    &:nth-child(2){
      background-color: #EBEBEB;
    }
  }

  &_team_logo {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;

  }


    .sb_score_team_logo_image{
      height: 45px;
    }

  &_team_counter{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 32px;
    box-shadow: -2px 0px 19px rgba(0, 0, 0, 0.11);

    &:nth-child(1){
      transform: skewX(35.0deg) scale(1.1) translate(-35px);
      background-color: #252525;
      color: #fff;

      .sb_score_team_counter_text{
        transform: skewX(-35.0deg);
      }

    }

    &:nth-child(2){
      transform: skewX(-35.0deg) scale(1.1) translate(35px);
      background-color: #fff;
      color: #202020;

      .sb_score_team_counter_text{
        transform: skewX(35.0deg);
      }
    }
  }

}

.sb_confetti_canvas{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

@media only screen and (max-width: 700px) {
 .sb_scoreboard_container{
   transform: scale(0.5);
 }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
 .sb_scoreboard_container{
   transform: scale(0.8);
 }
}

</style>
