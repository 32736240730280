<template>
  <div>
    <div class="sb_scene_small_sceen-msg">
      <div class="sb_scene_small_sceen-msg">
        <div class="sb_scene_small_sceen-msg_text">
          <b>Deze applicatie is voor grotere schermen :).</b>
        </div>
      </div>
    </div>
    <Backdrop
      v-if="teamOneLeading !== null"
      :shapes-animated="showProfitForm"
      :team-one-leading="teamOneLeading"
      :score-one="teamOne.score"
      :score-two="teamTwo.score"
    />
    <div
      :class="{sb_white:teamOneLeading}"
      class="sb_layer_2"
    >
      <transition>
        <EditProfit
          v-if="showProfitForm"
          @close="hideProfitForm"
        />
      </transition>
    </div>

    <div
      class="layer-3 container"
      :class="{sb_scoreboard_animation: showProfitForm }"
    >
      <Scoreboard
        :score-one="teamOne.score"
        :score-two="teamTwo.score"
        :team-one-name="teamOne.name"
        :team-two-name="teamTwo.name"
        :days-remaining="daysRemaining()"
      />

      <button
        class="sb_revenue_form_button"
        @click="animateShapes(); showProfitForm =!showProfitForm"
      >
        Update winst
      </button>


      <div class="sb_current_account">
        <div
          class="sb_current_account_name"
          @click="showLogout =! showLogout"
        >
          {{ currentUser }} <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.998 17L18.998 9H4.99805L11.998 17Z"
              fill="black"
            />
          </svg>
        </div>
        <button
          v-if="showLogout"
          class="sb_current_account_logout"
          @click="logout()"
        >
          Uitloggen
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import Scoreboard from '@/components/Scoreboard.vue';
import Backdrop from '@/components/Backdrop.vue';
import EditProfit from '@/components/EditProfit.vue';
import firebase from 'firebase';
import anime from 'animejs';

export default {
  components: {
    Scoreboard,
    Backdrop,
    EditProfit
  },
  data() {
    return {
      showProfitForm: false,
      scoreBoardAnimated: false,
      shapesAreAnimated: false,
      showLogout: false,
      currentUser: '',
      challengeInfo: {
        name: 'Profit Challenge',
        deadline: '2021-04-01',
        description: 'this is a challenge between eli5 and stijlbreuk'
      },
      teamOne: {
        name: 'ElI5',
        score: null
      },
      teamTwo: {
        name: 'Stijlbreuk',
        score: null
      }
    };
  },
  computed: {
    teamOneLeading() {
      if (this.teamOne.score === null || this.teamTwo.score === null) return null;
      return this.teamOne.score > this.teamTwo.score;
    }
  },
  created() {
    const dataRef = firebase.firestore().collection('teams');

    dataRef.doc(process.env.VUE_APP_FIREBASE_TEAMONE_ID).onSnapshot((doc) => {
      this.teamOne.score = doc.data().score;
      this.teamOne.name = doc.data().name;
    });


    dataRef.doc(process.env.VUE_APP_FIREBASE_TEAMTWO_ID).onSnapshot((doc) => {
      this.teamTwo.score = doc.data().score;
      this.teamTwo.name = doc.data().name;
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.firestore().collection('teams').doc(user.uid).onSnapshot((doc) => {
          this.currentUser = doc.data().name;
        });
      }
    });
  },
  mounted() {
    this.animateUIButtons();
  },
  methods: {
    daysRemaining() {
      const total = Date.parse(this.challengeInfo.deadline) - Date.parse(new Date());
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return days;
    },
    animateShapes() {
      this.shapesAreAnimated = true;
      this.scoreBoardAnimated = true;
    },
    hideProfitForm() {
      this.showProfitForm = !this.showProfitForm;
    },
    logout() {
      firebase.auth().signOut().then(() => {
        this.$router.replace('login');
      });
    },
    animateUIButtons() {
      anime({
        targets: '.sb_revenue_form_button, .sb_current_account',
        opacity: [
          0,
          1
        ],
        duration: 100,
        easing: 'easeOutQuad',
        delay: 4200
      });
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container{
  flex-direction: column;
}


@import url("https://rsms.me/inter/inter.css");

html {
  font-family: "Inter UI", sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter UI", sans-serif;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #F2f2f2;
}

.test{
  display: none;
}

.sb_button{
  width: 200px;
  background-color: white;
  height: 35px;
  margin-top: 2rem;
  border: none;

}

.sb_white{
  color: #fff;
}

.sb_revenue_form_button{
  width: 180px;
  background-color: #202020;box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  color: white;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
  margin-top: 3rem;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  transition: .3s;
}

.sb_revenue_form_button:hover{
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.sb_button_logout{
  background-color: white;
  height: 35px;
  margin-top: 2rem;
  border: none;

}

.v-enter-from{
  opacity: 0;
  transform: translateY(100px);
}

.v-enter-active{
  transition: all .3s ease-out;
  transition-delay: .2s;
}

.v-enter-to {
  opacity: 1;
}

.v-leave-from{
  opacity: 1;
}

.v-leave-active{
  transition: all .3s ease-out;
  transition-delay: .2s;

}

.v-leave-to{
  opacity: 0;
  transform: translateY(100px);;

}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//layer 3

.sb_layer_2{
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 6;
}

.layer-3{
  display: flex;
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  transition-duration: 1s;
}

  input{
    background-color: #fff;
    border-radius: 7px;
    border: none;
    height: 35px;
    color: #8F8F8F;
    padding: 0;
    padding-left: 15px;
    margin-right: 10px;
  }

  .sb_scoreboard_animation{
    transition: .4s ease-out;
    transform: scale(1.3);
    opacity: 0;
  }

  .sb_profit_form_visible{
    display: block;
  }

  .sb_current_account{
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;

    &_name{
      background-color: white;
      height: 35px;
      width: 125px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: 600;
      font-size: 14px;
      transition: .3s;
    }

    &_name:hover{
      transform: scale(1.05);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    &_logout{
        background-color: white;
        height: 35px;
        width: 100px;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        border: none;
        margin-top: 8px;
        font-weight: 500;
      transition: .3s;
    cursor: pointer;
    }

    &_logout:hover{
      transform: scale(1.05)
    }


  }

  .sb_scene_small_sceen-msg{
    position: absolute;
    z-index: 999;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: #f2f2f2;

       @media screen and (max-width: 1100px){
        display: flex;
        pointer-events: inherit;
        align-items: center;
        justify-content: center;
      }
  }

</style>
