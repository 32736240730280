import { createWebHashHistory, createRouter } from 'vue-router';
import firebase from 'firebase';
import Login from '@/views/Login.vue';
import Dashboard from '../views/Dashboard.vue';

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/register',
  //   component: Register
  // },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const { currentUser } = firebase.auth();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  to.meta.currentUser = currentUser;

  if (requiresAuth && !currentUser) next('login');
  else if (!requiresAuth && currentUser) next('dashboard');
  else next();
});


export default router;
