<template>
  <div class="container">
    <div class="sb_profit">
      <form @submit.prevent="submitProfit">
        <div>
          <img
            src="/images/profit-update.png"
            alt=""
          >
          <div class="sb_profit_title">
            Winst Update
          </div>
          <p>Vul hier de totale winst in. :)</p>
          <input
            v-model.number="enteredProfit"
            placeholder="0"
            class="sb_profit_input"
            type="number"
          >
          <button
            class="sb_profit_button_submit"
          >
            Opslaan
          </button>
        </div>
      </form>
      <button
        class="sb_profit_button_cancel"
        @click="close"
      >
        Annuleren
      </button>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  data() {
    return {
      enteredProfit: null
    };
  },
  computed: {
    user() {
      return this.$route.meta.currentUser;
    }
  },
  methods: {
    submitProfit() {
      if (this.user) {
        firebase.firestore().collection('teams').doc(this.user.uid).update({
          score: this.enteredProfit
        })
          .then(() => {
          })
          .catch((error) => {
            console.log(`error${error}`);
          });
      }
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
  .sb_profit{
    width: 400px;

    &_input::-webkit-outer-spin-button,
    &_input::-webkit-inner-spin-button,{
        -webkit-appearance: none;
    }

    &_input:active{
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        border: none;
    }

    &_title{
      font-size: 22px;
      font-weight: 800;
    }

    &_button{

      &_cancel{
        background-color: #fff;
        margin-top: 30px;
        border: none;
        height: 35px;
        width: 100px;
        border-radius: 5px;
        transition: .3s;
        cursor: pointer;
      }
      &_cancel:hover{
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        transform: scale(1.05);
      }

      &_submit{
        color: white;
        height: 35px;
        width: 75px;
        font-weight: 500;
        background-color: blue;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: .3s;
      }
            &_submit:hover{
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        transform: scale(1.05);
      }

    }
  }
</style>
